import React from "react";
import "./Services.css"; // Assurez-vous que le chemin est correct
import ebmsImage from "../../components/assets/ebms.png"; // Assurez-vous que le chemin est correct

function ServicesEbms() {
  return (
    <div className="service-page">
      {/* Titre et introduction en dehors de la section */}
      <h1 className="service-title">Electronic Board Management System (EBMS)</h1>
      <p className="service-intro">
        A board portal is a digital tool that facilitates secure communication between directors and senior executives on their chosen devices, anytime and anywhere.
      </p>
      
      {/* Section avec l'image à gauche et le texte à droite */}
      <section className="service-section">
        <div className="service-content-grid">
          <img
            src={ebmsImage} // Assurez-vous que ce chemin est correct
            alt="Electronic Board Management System"
            className="service-image"
          />
          <div className="service-details">
            <p className="features">Key Features include:</p>
            <ul className="features-list">
              <li>Enables users to conduct digital meetings, enhancing transparency and accountability within the organization.</li>
              <li>As a paperless meeting solution, it saves money on printing costs.</li>
              <li>Reduces preparation time and meets sustainability targets.</li>
              <li>Available on all platforms – whether iOS, Android, Windows, or Web – it allows decision-makers to access documents anytime and anywhere.</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ServicesEbms;
