import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

// Import des logos des réseaux sociaux avec fond noir
import facebookLogo from "../assets/facebook.svg";
import twitterLogo from "../assets/twitter.svg";
import instagramLogo from "../assets/instagram.svg";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-links">
        <ul className="footer-nav">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/services">Services</Link></li>
          <li><Link to="/contact">Contact</Link></li>
        </ul>
      </div>
      <div className="social-icons">
        <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
          <img src={facebookLogo} alt="Facebook" className="social-icon facebook-icon" />
        </a>
        <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
          <img src={twitterLogo} alt="Twitter" className="social-icon twitter-icon" />
        </a>
        <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
          <img src={instagramLogo} alt="Instagram" className="social-icon instagram-icon" />
        </a>
      </div>
      <div className="copyright">
        © 2024 AROUND EXPRESSIONS LDA Tous droits réservés
      </div>
    </footer>
  );
}

export default Footer;
