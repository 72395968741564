import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Home.css";
import backgroundImage from "../../components/assets/background.jpg";
import SimpleMap from "./sections/SimpleMap.js";
import AfricaSection from "./sections/AfricaSection.js";
import consultancyImage from "../../components/assets/consultancy.jpg";
import softwareImage from "../../components/assets/software.jpg";
import auditingImage from "../../components/assets/auditing.jpg";
import mediaMiningImage from "../../components/assets/media-mining.jpg";

function Home() {
  return (
    <div className="home">
      <div className="hero-section" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <Container>
          <Row>
            <Col>
              <div className="hero-text">
                <h1>AROUND EXPRESSIONS LDA</h1>
                <p>Software Solutions & Custom Applications | ICT & Telecommunications Infrastructure | Advanced Security Systems | Surveillance | AI & Analytics | Media Mining Monitoring System</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="info-section">
        <Container>
          <Row>
            <Col >
            <div className="info-block">
            <h2>Worldwide Presence</h2>
            <div className="map-and-text">
              <SimpleMap className="map-container" /> {/* Assurez-vous que SimpleMap a une classe appropriée si nécessaire */}
              <div className="map-text">
                <p>
                  Today, Around Expressions LDA is present on four continents and continues to expand its international activities:
                </p>
                <ul>
                  <li>Portugal</li>
                  <li>France</li>
                  <li>USA</li>
                  <li>Italy</li>
                  <li>Tunisia</li>
                  <li>Algeria</li>
                  <li>Libya</li>
                  <li>Egypt</li>
                  <li>Saudi Arabia</li>
                  <li>The UAE</li>
                </ul>
              </div>
            </div>
          </div>
            </Col>
            <Col md={6}>
              <div className="info-block">
                <AfricaSection />
              </div>
            </Col>
          </Row>

          {/* New Banner Section */}
         <Row>
            <Col>
                <div className="info-block">
                <h2>The Essence of What We Offer</h2>
                <div className="banner-grid">
                  <div className="banner-item" style={{ backgroundImage: `url(${consultancyImage})` }}>
                    <div className="banner-text">
                      <h3>Consultancy and Studies</h3>
                      <p>Around Expressions LDA provides expert consultancy services and conducts comprehensive studies tailored to meet the unique needs of each client. Our team of seasoned professionals is dedicated to delivering insights and strategies that drive success in an increasingly complex digital landscape.</p>
                    </div>
                  </div>
                  <div className="banner-item" style={{ backgroundImage: `url(${softwareImage})` }}>
                    <div className="banner-text">
                      <h3>Custom Software Development</h3>
                      <p>We specialize in the development of custom software solutions designed to address specific business requirements. Our approach ensures that the software we create is perfectly aligned with our clients' operational goals, providing them with the tools they need to thrive in their respective markets.</p>
                    </div>
                  </div>
                  <div className="banner-item" style={{ backgroundImage: `url(${auditingImage})` }}>
                    <div className="banner-text">
                      <h3>Auditing</h3>
                      <p>Our auditing services are meticulous and thorough, aimed at identifying areas of improvement and ensuring compliance with industry standards. We help our clients achieve excellence by offering detailed assessments and actionable recommendations.</p>
                    </div>
                  </div>
                  <div className="banner-item" style={{ backgroundImage: `url(${mediaMiningImage})` }}>
                    <div className="banner-text">
                      <h3>Social Media and Media Mining Systems</h3>
                      <p>Around Expressions LDA offers advanced social media and media mining systems that enable our clients to harness the power of digital platforms. Our solutions provide deep insights into online trends, audience behavior, and content performance, empowering businesses to make informed decisions and enhance their digital presence.</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
         </Row>
        </Container>
      </div>
    </div>
  );
}

export default Home;
