import React from "react";
import "./ServicesAdvanced.css"; // Assurez-vous que le chemin est correct
import safetyImage from "../../components/assets/safety.jpg"; // Assurez-vous que ce chemin est correct

function ServicesSafety() {
  return (
    <div className="service-page">
      {/* Titre et introduction en dehors de la section */}
      <h1 className="service-title">Operational Safety, Environmental Health & Advanced Security Solutions</h1>
      <p className="service-intro">
        Our technologically advanced solutions enable clients to stay ahead in rapidly evolving operational, production, compliance, environmental safety, and security needs.
      </p>
      
      {/* Section avec l'image à gauche et le texte à droite */}
      <section className="service-section">
        <div className="service-content-grid">
          <img
            src={safetyImage} // Assurez-vous que ce chemin est correct
            alt="Operational Safety and Advanced Security"
            className="service-image"
          />
          <div className="service-details">
            <p> In today’s dynamic and standards-driven market, industries face significant risks to human health, safety, and the environment due to their asset-intensive workplaces. Our web-based, real-time, cloud, or premise-hosted solutions assist in:</p>
            <ul className="features-list">
              <li>Monitoring, logging, managing, and escalating incidents of all kinds.</li>
              <li>Unifying new and existing emergency communications and evacuation modes.</li>
              <li>Managing workplace environmental, health, and safety.</li>
              <li>Enhancing physical human and asset security using advanced AI-powered analytical video surveillance.</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ServicesSafety;
