import React from 'react';
import './Services.css';
import avssImage from '../../components/assets/avss.jpg'; // Assurez-vous que le chemin est correct

const ServicesAnalytical = () => {
  return (
    <div className="service-page">
      {/* Titre et introduction en dehors de la section */}
      <h1 className="service-title">Analytical Video Surveillance System (AVSS)</h1>
      <p className="service-intro">
        We lead in designing and providing AI-powered Analytical IP Surveillance Solutions.
      </p>
      
      <div className="service-section">
        <div className="service-content-grid">
          <img
            src={avssImage} // Remplacez par le chemin réel de votre image
            alt="AVSS"
            className="service-image"
          />
          <div className="service-details">
            <p>
              These solutions, based on deep learning technology, offer a range of flexible features including:
            </p>
            <ul className="features-list">
              <li>Smart Video Search</li>
              <li>Face Recognition</li>
              <li>Intrusion & Loitering Detection</li>
              <li>Heat Mapping & Analysis</li>
              <li>License Plate & Container Code Recognition</li>
              <li>People-counting, Occupancy & Dwell Time Determination</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesAnalytical;
