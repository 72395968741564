import React from "react";
import "./Services.css"; // Assurez-vous que le chemin est correct
import mrmsImage from "../../components/assets/mrms.jpg"; // Assurez-vous que ce chemin est correct

function ServicesMrms() {
  return (
    <div className="service-page">
      {/* Titre et introduction en dehors de la section */}
      <h1 className="service-title">Meeting Room Management System (MRMS)</h1>
      <p className="service-intro">
        This web-based, cloud-hosted solution combines software and hardware to manage meeting spaces. Our solution helps clients streamline all meeting room activities, save time in finding and booking the correct meeting rooms, reduce costs of missed meetings, and make critical decisions regarding meeting room usage.
      </p>
      
      {/* Section avec l'image à gauche et le texte à droite */}
      <section className="service-section">
        <div className="service-content-grid">
          <img
            src={mrmsImage} // Assurez-vous que ce chemin est correct
            alt="Meeting Room Management System"
            className="service-image"
          />
          <div className="service-details">
            <p className="features">Supported modules include:</p>
            <ul className="features-list">
              <li>Reserve Rooms & Receive Invitations & Notifications</li>
              <li>View Upcoming Meetings</li>
              <li>Calendar Integration (Google Calendar, Office 365 & Exchange)</li>
              <li>Integration with MS Exchange CloudSync</li>
              <li>Office 365, Outlook via an Add-In, Google Apps, Alexa for Business, Chrome Extension for Google Calendar</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ServicesMrms;
