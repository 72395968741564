import React from "react";
import "./ServicesAdvanced.css"; // Assurez-vous que le chemin est correct
import ehssImage from "../../components/assets/ehss.jpg"; // Assurez-vous que ce chemin est correct

function ServicesEhss() {
  return (
    <div className="service-page">
      {/* Titre et introduction en dehors de la section */}
      <h1 className="service-title">Environmental, Health (EHSS)</h1>
      <p className="service-intro">
        Our EHSS is among the most technologically advanced and user-friendly EHS software available. 
      </p>
      
      {/* Section avec l'image à gauche et le texte à droite */}
      <section className="service-section">
        <div className="service-content-grid">
          <img
            src={ehssImage} // Assurez-vous que ce chemin est correct
            alt="Environmental, Health and Safety System"
            className="service-image"
          />
          <div className="service-details">
            <p>
            It aims to create safer, healthier, and more sustainable workplaces, where accidents are preventable, employee health is prioritized, and organizations can track, offset, and reduce their environmental impact.
            </p>
            <p className="features">Features include:</p>
            <ul className="features-list">
              <li>Environmental Performance Reporting</li>
              <li>Advanced Environmental Management</li>
              <li>Permit to Work/Safety Datasheets</li>
              <li>Document Management</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ServicesEhss;
