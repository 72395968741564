import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Hub/Home';
import CompanyOverview from './pages/Aboutus/CompanyOverview';
import Contactus from './pages/Contactus/Contactus';
import NavigationBar from "./components/Nav/NavigationBar";
import Footer from "./components/Footer/Footer";
import ServicesEbms from './pages/Services/ServicesEbms';
import ServicesHcms from './pages/Services/ServicesHcms';
import ServicesCrm from './pages/Services/ServicesCrm';
import ServicesEihs from './pages/Services/ServicesEihs';
import ServicesOverview from './pages/Services/ServicesOverview';
import ServicesAnalytical from './pages/Services/ServicesAnalytical';
import ServicesSmart from './pages/Services/ServicesSmart';
import ServicesAccessControl from './pages/Services/ServicesAccessControl';
import ServicesTimeAttendance from './pages/Services/ServicesTimeAttendance';
import ServicesCafm from './pages/Services/ServicesCafm';
import ServicesMrms from './pages/Services/ServicesMrms';
import ServicesAvms from './pages/Services/ServicesAvms';
import ServicesMams from './pages/Services/ServicesMams';
import ServicesSafety from './pages/Services/ServicesSafety';
import ServicesEhss from './pages/Services/ServicesEhss';
import ServicesIMS from './pages/Services/ServicesIMS';
import ServicesUemnes from './pages/Services/ServicesUemnes';
import OurValues from './pages/Aboutus/OurValues';
import News from './pages/Aboutus/News';
import NewsDetail from './pages/Aboutus/NewsDetail';
import OurExperience from './pages/Experience/OurExperience';
import './App.css';


function App() {
  return (
    
    <BrowserRouter>
    <div className="app">

    <NavigationBar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<CompanyOverview />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/services/ebms" element={<ServicesEbms />} />
        <Route path="/services/hcms" element={<ServicesHcms />} />
        <Route path="/services/crm" element={<ServicesCrm />} />
        <Route path="/services/eihs" element={<ServicesEihs />} />
        <Route path="/services/overview" element={<ServicesOverview />} />
        <Route path="/services/avss" element={<ServicesAnalytical />} />
        <Route path="/services/smart-key" element={<ServicesSmart />} />
        <Route path="/services/acs" element={<ServicesAccessControl />} />
        <Route path="/services/tams" element={<ServicesTimeAttendance />} />
        <Route path="/services/cafm" element={<ServicesCafm />} />
        <Route path="/services/mrms" element={<ServicesMrms />} />
        <Route path="/services/avms" element={<ServicesAvms />} />
        <Route path="/services/mams" element={<ServicesMams />} />
        <Route path="/services/safety-health" element={<ServicesSafety />} />
        <Route path="/services/ehss" element={<ServicesEhss />} />
        <Route path="/services/ims" element={<ServicesIMS />} />
        <Route path="/services/uemnes" element={<ServicesUemnes />} />
        <Route path="/ourvalues" element={<OurValues />} />
        <Route path="/news" element={<News />} />
        <Route path="/news/:id" element={<NewsDetail />} />
        <Route path="/experience" element={<OurExperience />}/>
      </Routes> 

      <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;