import React from 'react';
import africaImage from '../../../components/assets/background.jpg'; // Remplacez par l'image appropriée
import './AfricaSection.css';

function AfricaBanner() {
  return (
    <div className="africa-banner">
      <div className="africa-text">
        <h2>Around Expressions LDA in Africa</h2>
        <p>
          The digital effervescence of our continent, its cultural richness, and diversity make it conducive to co-creation and co-development. Around Expressions LDA is a witness and contributor to this technological transformation, offering our partners and clients new horizons.
        </p>
      </div>
      <div className="africa-image">
        {/* <img src={africaImage} alt="Africa" /> */}
      </div>
    </div>
  );
}

export default AfricaBanner;
