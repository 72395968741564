// frontend/src/components/pages/Ourservices/ServicesIMS.jsx
import React from "react";
import "./ServicesAdvanced.css"; // Assurez-vous que le chemin est correct
import imsImage from "../../components/assets/ims.jpg"; // Assurez-vous que le chemin est correct

function ServicesIMS() {
  return (
    <div className="service-page">
      {/* Titre et introduction */}
      <h1 className="service-title">Incident Management Software (IMS)</h1>
      <p className="service-intro">
        IMS is a web-based software used by staff to capture, track, resolve, report, and record incidents (security), operational safety, accidents, and near-misses in the workplace. 
      </p>
      
      {/* Section avec l'image à gauche et le texte à droite */}
      <section className="service-section">
        <div className="service-content-grid">
          <img
            src={imsImage} // Assurez-vous que ce chemin est correct
            alt="Incident Management Software"
            className="service-image"
          />
          <div className="service-details">
            <p>Users enter incidents themselves, ensuring fast and accurate data capture. Administrators monitor and follow up on incidents throughout their lifecycle, involving third parties like law enforcement or insurance representatives when necessary.</p>
            <p className="features">Features include:</p>
            <ul className="features-list">
              <li>Capture and track incidents in real-time</li>
              <li>Resolve and report incidents efficiently</li>
              <li>Record operational safety, accidents, and near-misses</li>
              <li>Enable fast and accurate data capture by users</li>
              <li>Monitor and follow up on incidents throughout their lifecycle</li>

            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ServicesIMS;
