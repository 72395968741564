import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './News.css'; // Assurez-vous d'avoir ce fichier CSS pour le style

const News = () => {
  // Liste des articles avec année
  const allNewsArticles = [
    {
      id: 1,
      year: 2024,
      title: "Exciting New Product Launch",
      date: "July 15, 2024",
      summary: "We are thrilled to announce the launch of our new product line, designed to revolutionize the industry with cutting-edge technology.",
      image: "path/to/product-launch-image.jpg"
    },
    {
      id: 2,
      year: 2024,
      title: "Company Expansion into New Markets",
      date: "June 20, 2024",
      summary: "Around Expressions LDA is expanding its operations into new markets to better serve our international clients and explore new opportunities.",
      image: "path/to/expansion-image.jpg"
    },
    {
      id: 3,
      year: 2023,
      title: "Award for Best Innovation",
      date: "May 10, 2023",
      summary: "We are honored to receive the award for Best Innovation at the Industry Excellence Awards, recognizing our commitment to pushing the boundaries of technology.",
      image: "path/to/award-image.jpg"
    },
    // Ajoutez plus d'articles ici
  ];

  // Calculer l'année actuelle
  const currentYear = new Date().getFullYear();
  // Obtenir les années disponibles
  const years = Array.from(new Set(allNewsArticles.map(article => article.year))).sort((a, b) => b - a);

  // État pour l'année sélectionnée
  const [selectedYear, setSelectedYear] = useState(currentYear);

  // Filtrer les articles en fonction de l'année sélectionnée
  const filteredArticles = allNewsArticles.filter(article => article.year === selectedYear);

  return (
    <div className="news-container">
      

      <h1 className="news-title">Company News</h1>

      <div className="year-selector">
        {years.map(year => (
          <button
            key={year}
            className={`year-button ${selectedYear === year ? 'active' : ''}`}
            onClick={() => setSelectedYear(year)}
          >
            {year}
          </button>
        ))}
      </div>

      <div className="news-grid">
        {filteredArticles.length > 0 ? (
          filteredArticles.map(article => (
            <div key={article.id} className="news-card">
                <img src={article.image} alt={article.title} className="news-card-image" />
                <Link to={`/news/${article.id}`} className="news-card-link">
                <h2 className="news-card-title">{article.title}</h2>
                </Link>
                <p className="news-card-date">{article.date}</p>
                <p className="news-card-summary">{article.summary}</p>
              
            </div>
          ))
        ) : (
          <p>No news available for the selected year.</p>
        )}
      </div>
    </div>
  );
};

export default News;
