import React from 'react';
import './ServicesAdvanced.css'; // Assurez-vous que ce chemin est correct
import acsImage from '../../components/assets/acs.jpg'; // Mettez à jour ce chemin avec le chemin correct

const ServicesAccessControl = () => {
  return (
    <div className="service-page">
      {/* Titre et introduction en dehors de la section */}
      <h1 className="service-title">Access Control System (ACS)</h1>
      <p className="service-intro">
        Designed for reliability and scalability, our ACS offers centralized monitoring and control across multiple locations and integrates with emergency systems, CCTV, Automated Visitor Management, and more.
      </p>
      
      <div className="service-section">
        <div className="service-content-grid">
          <img
            src={acsImage} // Mettez à jour avec le chemin réel de votre image
            alt="Access Control System"
            className="service-image"
          />
          <div className="service-details">
            <p>
              Our Access Control System (ACS) is designed to provide reliable and scalable solutions for managing access across various locations. It offers centralized monitoring and integrates seamlessly with:
            </p>
            <ul className="features-list">
              <li>Emergency Systems</li>
              <li>CCTV</li>
              <li>Automated Visitor Management</li>
              <li>And more...</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesAccessControl;
