import React, { useState } from 'react';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
import './SimpleMap.css';

const GEO_URL = 'https://raw.githubusercontent.com/datasets/geo-countries/master/data/countries.geojson';

const locations = [
  { coordinates: [-9.142685, 38.736946], label: "Portugal" },
  { coordinates: [2.352222, 48.856613], label: "France" },
  { coordinates: [-122.419418, 37.774929], label: "USA" },
  { coordinates: [12.496366, 41.902782], label: "Italy" },
  { coordinates: [9.537499, 33.886917], label: "Tunisia" },
  { coordinates: [3.086472, 36.737232], label: "Algeria" },
  { coordinates: [13.191239, 32.887209], label: "Libya" },
  { coordinates: [30.802498, 26.820553], label: "Egypt" },
  { coordinates: [45.079162, 23.885942], label: "Saudi Arabia" },
  { coordinates: [53.847818, 23.424076], label: "UAE" },
];

function SimpleMap() {
  const [hoveredLocation, setHoveredLocation] = useState(null);

  // Fonction pour empêcher les clics sur la carte de faire quelque chose
  const handleClick = (e) => {
    e.stopPropagation(); // Empêche la propagation des événements de clic
    e.preventDefault();  // Empêche l'action par défaut des clics
  };

  return (
    <div className="map-container" onClick={handleClick}>
      <ComposableMap
        projection="geoMercator"
        projectionConfig={{ scale: 150 }}
        style={{ width: "100%", height: "100%" }} // Assurez-vous que la carte utilise toute la largeur et la hauteur du conteneur
      >
        <Geographies geography={GEO_URL}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill="#E0F7FA" // Couleur de fond des continents (bleu clair)
                stroke="#B0BEC5" // Couleur des frontières des pays (gris clair)
                strokeWidth={0.5} // Épaisseur des frontières des pays
                onClick={handleClick} // Empêche les clics sur la géographie
              />
            ))
          }
        </Geographies>
        {locations.map((loc, index) => (
          <Marker
            key={index}
            coordinates={loc.coordinates}
            onMouseEnter={() => setHoveredLocation(loc.label)}
            onMouseLeave={() => setHoveredLocation(null)}
          >
            <circle r={6} fill="#FF5722" stroke="#000" strokeWidth={1} />
            {hoveredLocation === loc.label && (
              <text
                textAnchor="middle"
                y={-12}
                fontSize={12}
                fill="#FF5722"
                pointerEvents="none" // Désactive les interactions de la souris avec les labels
              >
                {loc.label}
              </text>
            )}
          </Marker>
        ))}
      </ComposableMap>
    </div>
  );
}

export default SimpleMap;
