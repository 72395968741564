import React from "react";
import "./Services.css"; // Assurez-vous que le chemin est correct
import cafamImage from "../../components/assets/cafm.jpg"; // Assurez-vous que ce chemin est correct

function ServicesCafm() {
  return (
    <div className="service-page">
      {/* Titre et introduction en dehors de la section */}
      <h1 className="service-title">Computer-Aided Facility & Asset Management (CAFAM)</h1>
      <p className="service-intro">
        Overall visibility of maintenance status and assets in any institution is critical. A Computer-Aided Facility Management (CAFM) System is a software tool used to centrally plan, manage, and maintain the work and resources of an organization, accessible via a web browser or mobile app.
      </p>
      
      {/* Section avec l'image à gauche et le texte à droite */}
      <section className="service-section">
        <div className="service-content-grid">
          <img
            src={cafamImage} // Assurez-vous que ce chemin est correct
            alt="Computer-Aided Facility & Asset Management"
            className="service-image"
          />
          <div className="service-details">
            <p className="features">Key Modules in our CAFM solution include:</p>
            <ul className="features-list">
              <li>Assets (Register, Tracking & Management)</li>
              <li>Work Requests & Job Requests on Assets or Facilities</li>
              <li>Contracts & Contractors Management</li>
              <li>Preventive & Reactive Maintenance</li>
              <li>Stock & Cost Control</li>
              <li>Work Planning and Supplier Management</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ServicesCafm;
