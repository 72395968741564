import React from "react";
import "./ServicesAdvanced.css"; // Assurez-vous que le chemin est correct
import hcmsImage from "../../components/assets/hcms.png"; // Assurez-vous que le chemin est correct

function ServicesHcms() {
  return (
    <div className="service-page">
      {/* Titre et introduction en dehors de la section */}
      <h1 className="service-title">Helpdesk & Complaint Management System (HCMS)</h1>
      <p className="service-intro">
        A Complaint Management System (CMS) is a web-based platform where consumers of a certain product or service can lodge their complaints or request help.
      </p>
      
      {/* Section avec l'image à gauche et le texte à droite */}
      <section className="service-section">
        <div className="service-content-grid">
          <img
            src={hcmsImage} // Assurez-vous que ce chemin est correct
            alt="Helpdesk & Complaint Management System"
            className="service-image service-image-hcms"
          />
          <div className="service-details">
            <p className="features">Transform your customer support from good to great through:</p>
            <ul className="features-list">
              <li>Quick and accurate complaint resolution</li>
              <li>Self-service enabled by the system’s knowledgebase</li>
              <li>Customer satisfaction surveys</li>
              <li>Integrations that provide access to all other support needs directly through our CMS</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ServicesHcms;
