import React from 'react';
import './ServicesAdvanced.css'; // Assurez-vous que ce chemin est correct
import tamsImage from '../../components/assets/tams.jpg'; // Mettez à jour ce chemin avec le chemin correct

const ServicesTimeAttendance = () => {
  return (
    <div className="service-page">
      {/* Titre et introduction en dehors de la section */}
      <h1 className="service-title">Time & Attendance Management System (TAMS)</h1>
      <p className="service-intro">
        Our Time & Attendance Management System (TAMS) utilizes hardware devices installed at entry and exit points to accurately capture user entries and exits. These Biometric Time & Attendance Systems seamlessly integrate with third-party payroll systems, ERPs, Access Control Hardware, and SMS/Email Notification Systems.
      </p>
      
      <div className="service-section">
        <div className="service-content-grid">
          <img
            src={tamsImage} // Mettez à jour avec le chemin réel de votre image
            alt="Time & Attendance Management System"
            className="service-image"
          />
          <div className="service-details">
          <p>
              This integration ensures efficient management of workforce attendance while maintaining high security standards and ease of use across various authentication methods.
            </p>
            <p className='features'>
             Features include:
            </p>
            <ul className="features-list">
              <li>Support for multiple credentials including Palm Vein</li>
              <li>Fingerprint</li>
              <li>Facial Recognition</li>
              <li>Iris Scans</li>
              <li>PIN</li>
              <li>RFID Cards of all classes</li>
            </ul>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesTimeAttendance;
