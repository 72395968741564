import React from 'react';
import './ServicesAdvanced.css';
import eihsImage from '../../components/assets/eihs.jpg'; // Assurez-vous que le chemin est correct

const ServicesEihs = () => {
  return (
    <div className="service-page">
      {/* Titre et introduction en dehors de la section */}
      <h1 className="service-title">Enterprise IT Helpdesk System (EIHS)</h1>
      <p className="service-intro">
        EIHS is a web-based system that supports IT personnel in managing and resolving user incidents and requests. It facilitates efficient incident tracking, prioritization, and resolution, ensuring minimal disruption to IT services. EIHS integrates with other IT management tools to optimize IT service delivery.
      </p>
      
      {/* Section avec l'image à gauche et le texte à droite */}
      <section className="service-section">
        <div className="service-content-grid">
          <img
            src={eihsImage} // Assurez-vous que ce chemin est correct
            alt="Enterprise IT Helpdesk System"
            className="service-image"
          />
          <div className="service-details">
            <p>
              Our web-based Enterprise IT Helpdesk software empowers IT personnel to manage user IT incidents and requests efficiently within an IT environment. Following international standards, the system logs, classifies, investigates, and resolves issues.
            </p>
            <p className='features'>Features include:</p>
            <ul className="features-list">
              <li>Incident Management</li>
              <li>SLA Management</li>
              <li>Knowledge Base Management</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServicesEihs;
