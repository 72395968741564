// frontend/src/components/pages/Ourservices/ServicesUemnes.jsx
import React from "react";
import "./ServicesAdvanced.css";
import uemnesImage from "../../components/assets/uemnes.jpg"; // Assurez-vous que ce chemin est correct

function ServicesUemnes() {
  return (
    <div className="service-page">
      <h1 className="service-title">Unified Emergency Mass Notification/Evacuation System (UEMNES)</h1>
      <p className="service-intro">
        Our Unified Emergency Mass Notification and Crisis Communication Solution integrates software and hardware to initiate notifications and activate multiple output modes.
      </p>
      <section className="service-section">
        <div className="service-content-grid">
          <img
            src={uemnesImage}
            alt="Unified Emergency Mass Notification/Evacuation System"
            className="service-image"
          />
          <div className="service-details">
            <p className="features">Key features include:</p>
            <ul className="features-list">
              <li>Broadcast or targeted alerts</li>
              <li>100% coverage assurance for broadcast message consumption</li>
              <li>Fills functionality gaps not covered by existing systems</li>
              <li>Deployed for Unified Mass, In-building, Outdoor, and Mobile Notifications</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ServicesUemnes;
