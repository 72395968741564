//frontend/src/components/Nav/NavigationBar.jsx
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import DropDownMenu from "../DropDown/DropDownMenu";
import "./NavigationBar.css";

import logo from "../assets/Around_logo.png";

function NavigationBar() {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(null);

  // Utiliser useEffect pour fermer le menu lors du changement de route
  useEffect(() => {
    // Fermer le menu lorsque l'URL change
    setActiveMenu(null);
  }, [location.pathname]);

  const handleButtonClick = (menu) => {
    setActiveMenu(activeMenu === menu ? null : menu);
  };

  const handleCloseMenu = () => {
    setActiveMenu(null);
  };

  return (
    <header className="header">
      <nav className="nav">

        {/* Logo */}
        <Link to="/">
          <img src={logo} alt="Logo Around" className="logo" />
        </Link>

        <ul>
          {/* <NavItem to="/" active={location.pathname === "/"}>Home</NavItem> */}
          <NavItem  active={location.pathname === "/about"} onClick={() => handleButtonClick('about')}>About</NavItem>
          <NavItem  active={location.pathname === "/services"} onClick={() => handleButtonClick('services')}>Services</NavItem>
          <NavItem to="/experience" active={location.pathname === "/experience"}>Our Experience</NavItem>
          <NavItem to="/contactus" active={location.pathname === "/contactus"}>Contact</NavItem>
        </ul>
      </nav>
      <DropDownMenu activeMenu={activeMenu} onClose={handleCloseMenu} />
    </header>
  );
}

// Composant pour chaque élément de navigation
function NavItem({ to, active, onClick, children }) {
  return (
    <li className={active ? "active" : ""} onClick={onClick}>
      <Link to={to}>{children}</Link>
    </li>
  );
}

export default NavigationBar;
