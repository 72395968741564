import React from 'react';
import './ServicesAdvanced.css'; // Assurez-vous que le chemin est correct
import crmImage from '../../components/assets/crm.jpg'; // Assurez-vous que le chemin est correct

const ServicesCrm = () => {
  return (
    <div className="service-page">
      {/* Titre et introduction en dehors de la section */}
      <h1 className="service-title">Customer Relations Management (CRM)</h1>
      <p className="service-intro">
        A CRM is a business system designed to manage interactions with customers and prospects. The aim is to develop longer-lasting relationships with customers, drive business growth, and increase customer loyalty.
      </p>
      
      {/* Section avec l'image à gauche et le texte à droite */}
      <section className="service-section">
        <div className="service-content-grid">
          <img
            src={crmImage} // Assurez-vous que ce chemin est correct
            alt="Customer Relations Management"
            className="service-image"
          />
          <div className="service-details">
            <p className='features'>Improve your customer relations through:</p>
            <ul className="features-list">
              <li>Managing leads</li>
              <li>Turning leads into deals, enabling you to close them faster</li>
              <li>Website and in-app tracking</li>
              <li>Built-in phone and email features to automate communications</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServicesCrm;
