import React from "react";
import "./CompanyOverview.css"; // Assurez-vous que ce fichier CSS est lié
import OfficeImage from "../../components/assets/office.jpg";

const CompanyOverview = () => {
  return (
    <div className="company-overview-container">
      <div className="header-section">
        <h1 className="company-title">Around Expressions LDA</h1>
        <p className="company-tagline">
          Dynamic and Innovative Solutions for the Modern Business World
        </p>
      </div>

      <div className="content-section">
        <div className="image-section">
          <img src= {OfficeImage} alt="Office" className="office-image" />
        </div>
        <div className="text-section">
          <h2 className="section-title">Who We Are</h2>
          <p>
            Around Expressions LDA is a dynamic and innovative company,
            specializing in development, consulting, auditing, and social
            media. With international experience, our company is committed to
            providing tailor-made solutions that meet our clients' specific
            needs.
          </p>
          <p>
            Our team of experts is passionate about delivering high-quality
            services that drive success and growth for businesses around the
            world. We pride ourselves on our ability to understand the unique
            challenges of each market and develop strategies that deliver
            tangible results.
          </p>
        </div>
      </div>

      <div className="stats-section">
        <div className="stat-item">
          <h3>10+</h3>
          <p>Years of Experience</p>
        </div>
        <div className="stat-item">
          <h3>50+</h3>
          <p>International Clients</p>
        </div>
        <div className="stat-item">
          <h3>100%</h3>
          <p>Commitment to Excellence</p>
        </div>
      </div>

        <section class="mission-section">
        <div class="container">
            <h2 class="section-title">Our Mission</h2>
            <div class="mission-points">
            <div class="mission-point">
                <i class="fas fa-rocket mission-icon"></i>
                <h3>Innovation</h3>
                <p>We believe in the importance of innovation to stay competitive and offer cutting-edge solutions.</p>
            </div>
            <div class="mission-point">
                <i class="fas fa-trophy mission-icon"></i>
                <h3>Excellence</h3>
                <p>Quality is our top priority, and we strive to exceed our clients' expectations.</p>
            </div>
            <div class="mission-point">
                <i class="fas fa-handshake mission-icon"></i>
                <h3>Commitment</h3>
                <p>We are dedicated to our clients' success and are committed to providing exceptional service at every stage of the project.</p>
            </div>
            </div>
        </div>
     </section>
    </div>
  );
};

export default CompanyOverview;
