import React from 'react';
import { useParams } from 'react-router-dom';

const NewsDetail = () => {
  const { id } = useParams();
  
  // Vous devriez probablement récupérer les détails de l'article depuis un API ou une base de données
  // Ici, nous utiliserons des données fictives pour l'exemple
  const newsArticle = {
    1: {
      title: "Exciting New Product Launch",
      date: "July 15, 2024",
      content: "Detailed content about the exciting new product launch."
    },
    2: {
      title: "Company Expansion into New Markets",
      date: "June 20, 2024",
      content: "Detailed content about the company's expansion into new markets."
    },
    3: {
      title: "Award for Best Innovation",
      date: "May 10, 2024",
      content: "Detailed content about the award for best innovation."
    }
  };

  const article = newsArticle[id];

  return (
    <div className="news-detail-container">
      <h1>{article.title}</h1>
      <p>{article.date}</p>
      <div>{article.content}</div>
    </div>
  );
};

export default NewsDetail;
