import React from 'react';
import './ServicesAdvanced.css'; // Assurez-vous que ce chemin est correct
import smartKeyImage from '../../components/assets/smartkey.jpg'; // Mettez à jour ce chemin avec le chemin correct

const ServicesSmart = () => {
  return (
    <div className="service-page">
      {/* Titre et introduction en dehors de la section */}
      <h1 className="service-title">Smart Key Management Solutions (SMART-KEY)</h1>
      <p className="service-intro">
        Our smart and secure key management system integrates Access Cards, PINs, and Biometric Fingerprints to control access to fleet and premise keys.
      </p>
      
      <div className="service-section">
        <div className="service-content-grid">
          <img
            src={smartKeyImage} // Mettez à jour avec le chemin réel de votre image
            alt="Smart Key Management"
            className="service-image"
          />
          <div className="service-details">
            <p>
              It ensures accountability with RFID or iButton-tagged keys, integrates with third-party security systems, and supports access scheduling and recording.
            </p>
            <p className='features'>
                Features include:
            </p>
            <ul className="features-list">
              <li>Integrates Access Cards</li>
              <li>PIN and Biometric Fingerprints</li>
              <li>Fleet and Premise Key Control</li>
              <li>Accountability with RFID/iButton</li>
              <li>Third-Party Security System Integration</li>
              <li>Access Scheduling and Recording</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesSmart;
