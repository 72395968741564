// frontend/src/components/DropDown/DropDownMenu.js
import React, { useEffect, useRef } from 'react';
import './DropDownMenu.css';
import DropDownAbout from './DropDownAbout';
import DropDownServices from './DropDownServices';

const DropDownMenu = ({ activeMenu, onClose }) => {
  const menuRef = useRef(null);
  const backdropRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        backdropRef.current &&
        !backdropRef.current.contains(event.target)
      ) {
        onClose(); // Fermer le menu lorsque vous cliquez en dehors
      }
    };

    // Ajouter l'écouteur d'événements
    document.addEventListener('mousedown', handleClickOutside);

    // Nettoyer l'écouteur d'événements lors du démontage du composant
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <>
      {activeMenu && <div className="backdrop show" ref={backdropRef} />}
      <div className={`dropdown-menu ${activeMenu ? 'show' : ''}`} ref={menuRef}>
        {activeMenu === 'about' && <DropDownAbout />}
        {activeMenu === 'services' && <DropDownServices />}
      </div>
    </>
  );
};

export default DropDownMenu;
