import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faUsers, faBriefcase, faGem, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import './DropDownMenu.css';

const DropDownAbout = () => (
  <div className="dropdown-content">
    <div className="categories">
      <ul>
        <li>
          <FontAwesomeIcon icon={faCog} className="icon" />
          <a href="/about">Company Overview</a>
        </li>
        <li>
          <FontAwesomeIcon icon={faUsers} className="icon" />
          <a href="/team">Our Team</a>
        </li>
        <li>
          <FontAwesomeIcon icon={faBriefcase} className="icon" />
          <a href="/careers">Careers</a>
        </li>
        <li>
          <FontAwesomeIcon icon={faGem} className="icon" />
          <a href="/ourvalues">Our Values</a>
        </li>
        <li>
          <FontAwesomeIcon icon={faNewspaper} className="icon" />
          <a href="/news">News</a>
        </li>
      </ul>
    </div>
  </div>
);

export default DropDownAbout;
