import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faShieldAlt, faBrain, faBuilding, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import './DropDownMenu.css';

const services = {
  "Software Solutions & Custom Applications": [
    { name: "Electronic Board Management System", link: "/services/ebms" },
    { name: "Helpdesk & Complaint Management System", link: "/services/hcms" },
    { name: "Customer Relations Management", link: "/services/crm" },
    { name: "Enterprise IT Helpdesk System", link: "/services/eihs" },
  ],
  "Advanced Security Systems": [
    { name: "Analytical Video Surveillance System", link: "/services/avss" },
    { name: "Smart Key Management Solutions", link: "/services/smart-key" },
    { name: "Access Control System", link: "/services/acs" },
    { name: "Time & Attendance Management System", link: "/services/tams" },
  ],
  // "AI & Analytics": [
  //   { name: "Media Mining Monitoring System", link: "/services/media-mining" },
  // ],
  "Enterprise Facilities & Asset Management Systems (EFAMS)": [
    { name: "Computer-Aided Facility & Asset Management", link: "/services/cafm" },
    { name: "Meeting Room Management System", link: "/services/mrms" },
    { name: "Automated Visitor Management System", link: "/services/avms" },
    { name: "Mobile Assets Management System", link: "/services/mams" },
  ],
  "Operational Safety & Environmental Health": [
    { name: "Operational Safety", link: "/services/safety-health" },
    { name: "Environmental, Health and Safety System", link: "/services/ehss" },
    { name: "Incident Management Software", link: "/services/ims" },
    { name: "Unified Emergency Mass Notification/Evacuation System", link: "/services/uemnes" },
  ],
};

const specialLinks = {
  "Software Solutions & Custom Applications": { name: "Overview Solutions", link: "/services/overview" },
};

const categoryIcons = {
  "Software Solutions & Custom Applications": faCog,
  "Advanced Security Systems": faShieldAlt,
  "AI & Analytics": faBrain,
  "Enterprise Facilities & Asset Management Systems (EFAMS)": faBuilding,
  "Operational Safety & Environmental Health": faExclamationTriangle,
};

const categoryDescriptions = {
  "Software Solutions & Custom Applications": "We offer tailored software solutions to meet your unique business needs.",
  "Advanced Security Systems": "Our advanced security systems ensure your safety and protection.",
  "AI & Analytics": "Leverage AI and analytics to gain insights and improve decision-making.",
  "Enterprise Facilities & Asset Management Systems (EFAMS)": "Manage your facilities and assets efficiently with our comprehensive systems.",
  "Operational Safety & Environmental Health": "Protect your operations and environment with our safety and health solutions.",
};

const DropDownServices = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);


  const handleCategoryClick = (category) => {
    if (selectedCategory === category) {
      setSelectedCategory(null); // Déselectionner si la catégorie cliquée est déjà sélectionnée
    } else {
      setSelectedCategory(category);
    }
  };

  return (
    <div className="dropdown-content">
      <div className="categories">
        <ul>
          {Object.keys(services).map(category => (
            <li
              key={category}
              onClick={() => handleCategoryClick(category)}
              className={selectedCategory === category ? 'active' : ''} // Ajouter classe active si sélectionnée
            >
              <FontAwesomeIcon icon={categoryIcons[category]} className="icon" />
              {category}
            </li>
          ))}
        </ul>
      </div>
      <div className="services">
        {selectedCategory && (
          <>
            <p className="category-description">{categoryDescriptions[selectedCategory]}</p>
            <ul className="services-grid">
              {services[selectedCategory].map(service => (
                <li key={service.name}>
                  <Link to={service.link}>{service.name}</Link>
                </li>
              ))}
            </ul>
            {specialLinks[selectedCategory] && (
              <div>
                <p className="overview-description">For more information, check out our recap</p>
                <div className="overview-link">
                  <Link to={specialLinks[selectedCategory].link}>
                    {specialLinks[selectedCategory].name}
                  </Link>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
  
};

export default DropDownServices;
