import React, { useState } from 'react';
import './OurExperience.css'; // Assurez-vous d'ajouter les styles appropriés

const clients = [
  { name: 'Client A', logo: 'path/to/logoA.png' },
  { name: 'Client B', logo: 'path/to/logoB.png' },
  { name: 'Client C', logo: 'path/to/logoC.png' },
];

const caseStudies = [
  { title: 'Case Study 1', description: 'Description of Case Study 1', status: 'Completed' },
  { title: 'Case Study 2', description: 'Description of Case Study 2', status: 'In Progress' },
];

const OurExperience = () => {
  const [selectedTab, setSelectedTab] = useState('clients'); // État pour gérer l'onglet sélectionné

  return (
    <div className="experience-container">
      

      <div className="tab-banner">
      <h1>Our Experience</h1>
        
      </div>

      <div className="tab-buttons">
          <button
            className={selectedTab === 'clients' ? 'active' : ''}
            onClick={() => setSelectedTab('clients')}
          >
            Clients
          </button>
          <button
            className={selectedTab === 'case-studies' ? 'active' : ''}
            onClick={() => setSelectedTab('case-studies')}
          >
            Case Studies
          </button>
        </div>

      {selectedTab === 'clients' && (
        <section className="clients-section">
          <h2>Our Clients</h2>
          <div className="clients-grid">
            {clients.map(client => (
              <div key={client.name} className="client-card">
                <img src={client.logo} alt={client.name} className="client-logo" />
                <p>{client.name}</p>
              </div>
            ))}
          </div>
        </section>
      )}

      {selectedTab === 'case-studies' && (
        <section className="case-studies-section">
          <h2>Case Studies</h2>
          <div className="case-studies-list">
            {caseStudies.map(study => (
              <div key={study.title} className="case-study-card">
                <h3>{study.title}</h3>
                <p>{study.description}</p>
                <p>Status: {study.status}</p>
              </div>
            ))}
          </div>
        </section>
      )}
    </div>
  );
};

export default OurExperience;
